/* TODO: Delete all css rules and replace with normalize.css:  https://github.com/necolas/normalize.css/blob/master/normalize.css */

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
tr,
td,
span,
strong,
div,
input {
    font-family: 'Manrope', sans-serif !important;
    color: black;
}

body {
    background: rgb(24, 26, 26);
    overflow-x: hidden;
    font-size: 1rem;
}


/* This disables the page reload for mobile and tablet */
/* 1280px covers up to a 10.1 inch tablet screen */
@media only screen and (max-width: 1280px) {
    html, body {
        touch-action: none;
        overscroll-behavior-y: none !important;
    }
}

/* screen size > 1280px and has no hover (means it's a touch device) */
@media  only screen and (min-width: 1281px) and (hover: none) {
    html, body {
        touch-action: none;
        overscroll-behavior-y: none !important;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-align: center;
}

html {
    font-size: 14px;
}

.center {
    text-align: center;
}

.Toastify__toast-container {
    margin-top: 85px;
    pointer-events: none;
    background: transparent;
    z-index: 100001 !important;
    min-width: 300px;
}

@media only screen and (max-width: 600px) {
    .Toastify__toast-container {
        margin-top: 0px;
        width: 100%;
    }
}

.Toastify__toast-body {
    word-wrap: break-word;
    word-break: break-word;
}

.Toastify__toast-body > div {
    color: white !important;
}


@media only screen and (max-width: 600px) {
    .Toastify__toast-body {
        width: 100%;
    }
}

/* Start toast custom notifications */

/* Dark toast custom notification */
.Toastify__toast.Toastify__toast-theme--dark.Toastify__toast--default {
    background: linear-gradient(#121212 96%, #eed926 0) !important;
}

.Toastify__progress-bar-theme--dark {
    background-color: rgb(18, 18, 18) !important;
}

.Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar-theme--dark.Toastify__progress-bar--default {
    opacity: 1;
}

.Toastify__progress-bar--bg.Toastify__progress-bar-theme--dark.Toastify__progress-bar--default {
    opacity: 0;
}

/* Error toast custom notification */
.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error {
    background: linear-gradient(#121212 96%, rgb(231, 76, 60) 0) !important;
}

.Toastify__progress-bar--error {
    background-color: rgb(18, 18, 18) !important;
}

.Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar-theme--light.Toastify__progress-bar--error {
    opacity: 1;
}

/* Info toast custom notification */
.Toastify__toast.Toastify__toast--info {
    background: linear-gradient(#121212 96%, rgb(52, 152, 219) 0);
}

.Toastify__progress-bar--info {
    background-color: rgb(18, 18, 18) !important;
}

.Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar-theme--light.Toastify__progress-bar--info {
    opacity: 1;
}

/* End toast custom notifications */

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

.modal {
    z-index: 10000;
}

.modal-dialog {
    margin: auto;
    padding: 0px 5px;
}

div.CookieConsent div.cookie-button-wrapper {
    width: 90vw;
    display: flex;
    justify-content: center;
}

/* Hide unfilled Google AdSense */
ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
    /* border: 1px solid grey; */
}